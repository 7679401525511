import React from 'react';
import styled, { css } from 'styled-components';

import Button from '../ui/button';
import Progress from '../ui/progress';
import Overlay from '../ui/overlay';

import useCarousel from '../../hooks/useCarousel';

const Styles = styled.div`  
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  opacity: 0;
  transition: opacity 0.8s ease;
  
  ${p => p.isActive && css`
    opacity: 1;
  `}
`;

const Image = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url(${p => p.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const Text = styled.p`
  text-align: center;
  margin: 3rem 2rem;
  font-size: 1.2rem;

  color: ${p => p.theme.colors.background};
  
  z-index: 2;
  position: relative;
`;

const TextArea = styled.div`
  position: relative;
  z-index: 2;

  text-align: center;

  h1, p {
    color: ${p => p.theme.colors.background};
    text-shadow: ${p => p.theme.shadow.textShadow};    
  }
  h1 {
    font-weight: ${p => p.theme.fonts.weights.black};
    font-size: 6rem;
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 2rem;
    margin-bottom: 6rem;
  }

  button>h2 {
    font-weight: ${p => p.theme.fonts.weights.bold};
    font-size: 2rem;
    color: ${p => p.theme.colors.background};
  }

  @media(max-width: ${p => p.theme.breakpoints.xxl}) {
    width: 75%;
    h1 {
      font-size: 4rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
    button>h2 {
      font-size: 1.8rem;
    }
  }

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    padding: 0 16px;    
    width: 100%;

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
    button>h2 {
      font-size: 1.4rem;
    }
  }
`;

export default ({ className, items, smallBottom }) => {
  const [current, setCurrent] = useCarousel(items);

  const getContent = (item, index) => {
    const imageUrl = item?.kep?.url;

    if (item.szoveg) {
      return (
        <Content key={item.szoveg} isActive={index === current}>
          <Overlay />
          <Image img={imageUrl} />
          <Text>{item.szoveg}</Text>
        </Content>
      );
    }

    return (
      <Content key={item.cim} isActive={index === current}>
        <Overlay />
        <Image img={imageUrl} />
        <TextArea>
          <h1>{item.cim}</h1>
          <p>{item.leiras}</p>
          {item.ctaLink && item.cta && <Button link={item.ctaLink}><h2>{item.cta}</h2></Button>}
        </TextArea>
      </Content>
    );
  };

  return (
    <Styles className={className}>
      {items.map((item, index) => getContent(item, index))}
      <Progress current={current} setCurrent={setCurrent} items={items} position={smallBottom ? 20 : 80} />
    </Styles>
  );
};

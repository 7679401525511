import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  
  &>* {
    margin: 16px;
  }

  @media(max-width: ${p => p.theme.breakpoints.xxl}) {
    &>* {
      margin: 12px;
    }
  }

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    &>* {
      margin: 8px;
    }
  }
`;

import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  
  background-color: rgba(0, 0, 0, 0.33);
`;

export default Overlay;

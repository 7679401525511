import React from 'react';
import styled from 'styled-components';

import Row from './row';
import Dot from './dot';

const Progress = styled(Row)`
  position: absolute;
  z-index: 2;

bottom: ${p => p.position || 80}px;
  left: 50%;
  transform: translateX(-50%);
`;

export default ({
  items, current, setCurrent, position,
}) => (
  <Progress position={position}>
    {items.map((item, index) => (
      <Dot key={item.cim || item.szoveg} type="button" active={index === current} onClick={() => setCurrent(index)} />
    ))}
  </Progress>
);

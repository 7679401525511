import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Carousel from '../components/composite/carousel';

const HomeCarousel = styled(Carousel)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          translations {
            home
          }
        }
      }

      allStrapiKezdolap {
        edges {
          node {
            strapiId
            cim
            leiras
            cta
            ctaLink
            kep {
              id
              url
              formats
            }
          }
        }
      }
    }
  `);

  const {
    home: homeTitle,
  } = data.site.siteMetadata.translations;

  const homeItems = data.allStrapiKezdolap.edges.map(node => node.node);

  return (
    <Layout noMainMargins transparent>
      <SEO title={homeTitle} />
      <HomeCarousel items={homeItems} />
    </Layout>
  );
};

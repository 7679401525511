import styled, { css } from 'styled-components';

export default styled.button`
  width: 32px;
  height: 32px;

  border-radius: 50%;

  border: 3px solid ${p => p.theme.colors.background};

  background-color: transparent;

  ${p => p.active && css`
    background-color: ${p.theme.colors.background};    
  `}

  @media(max-width: ${p => p.theme.breakpoints.xxl}) {
    width: 24px;
    height: 24px;
    border: 2px solid ${p => p.theme.colors.background};
  }


  @media(max-width: ${p => p.theme.breakpoints.m}) {
    width: 16px;
    height: 16px;
    border: 2px solid ${p => p.theme.colors.background};
  }
`;

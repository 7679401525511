import { useState, useEffect } from 'react';

export default function useCarousel(items) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setCurrent((current + 1) % items.length), 5000);
    return () => clearInterval(intervalId);
  }, [current, items.length]);

  return [current, setCurrent];
}
